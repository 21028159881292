body {
  display: grid;
  height: 100vh;
  width: 100vw;
  background-color: #ff8c00;
  background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 16c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zm33.414-6l5.95-5.95L45.95.636 40 6.586 34.05.636 32.636 2.05 38.586 8l-5.95 5.95 1.414 1.414L40 9.414l5.95 5.95 1.414-1.414L41.414 8zM40 48c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zM9.414 40l5.95-5.95-1.414-1.414L8 38.586l-5.95-5.95L.636 34.05 6.586 40l-5.95 5.95 1.414 1.414L8 41.414l5.95 5.95 1.414-1.414L9.414 40z' fill='%23f26b00' fill-opacity='0.27' fill-rule='evenodd'/%3E%3C/svg%3E");
  place-items: center;
}

#root {
  display: flex;
  align-items: center;
  justify-content: center;
}

button {
  outline: none;
  border-radius: 0.4rem;
  padding: 1rem;
  border: none;
  background-color: darkorange;
  color: white;
  font-weight: bold;
  margin: 2rem 0 0;
}

h2 {
  margin-top: 0;
  line-height: 1.4;
}

p {
  text-align: right;
  align-self: flex-end;
}

button:hover {
  cursor: pointer;
}

.logo {
  animation: moveUpDown 4s ease-in-out infinite;
  position: absolute;
  top: 2rem;
  height: 110px;
  margin: 0 auto;
  width: 100vw;
}

@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(0)
  }
  50% {
    transform: translateY(-5px)
  }
}

.card {
  max-width: 500px;
  padding: 1.5rem;
  width: 70%;
  background-color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  border-radius: 8px;
  margin: 0 auto;
  box-shadow: -6px 12px 74px -19px #d97200;
  transition: all 0.3s ease-out;
}

.card:hover {
  transform: translateY(-3px);
}

.author {
  color: #888888;
}

.author::after {
  content: "";
  position: absolute;
  left: 0;
  display: inline-block;
  height: 1em;
  width: 100%;
  border-bottom: 2px dashed #ececec;
  margin-top: 23px;
}

.twitter {
  padding: 0.8rem;
  font-size: 1.5rem;
  align-self: flex-start;
  background-color: #1da1f2;
  outline: none;
  border-radius: 0.4rem;
  border: none;
  color: white;
  font-weight: bold;
  margin: 2rem 0 0;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.bouncy {
  animation: bouncy 6s infinite ease-out;
  position: relative;
}

@keyframes bouncy {
  0% {
    top: 0em;
  }
  40% {
    top: 0em;
  }
  43% {
    top: -0.2em;
  }
  46% {
    top: 0em;
  }
  48% {
    top: -0.1em;
  }
  50% {
    top: 0em;
  }
  100% {
    top: 0em;
  }
}

.hint {
  color: #653503;
  margin: 1rem auto 0;
  text-align: center;
  padding: 0 10%;
  line-height: 1.5;
}

.footer {
  position: absolute;
  bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer p {
  color: #653503;
  font-size: 0.7rem;
}

.footer a i {
  font-size: 1.8rem;
  color: #FFDAAD;
}

.footer i {
  transition: all 200ms ease-out;
}

.footer i:hover  {
  transform: translateY(-2px);
  color: white;
}

.footer div {
  display: flex;
  gap: 0.8rem;
}

@media only screen and (max-width: 440px) {
  .card {
    width: 80%;
    margin-bottom: 2rem;
  }

  .hint {
    display: none;
  }

  .logo {
    top: 2rem;
    height: 90px;
  }

  .footer {
    bottom: 1rem;
  }

}

@media only screen and (width: 420px) {
  body {
    background-color: white;
    background-image: none;
  }

  body::before {
    content: 'nice';
    font-family: "Comic Sans MS", "Comic Sans", sans-serif;
    top: 2rem;
    left: 30%;
    font-size: 5rem;
    position: absolute;
    z-index: 500;
  }
}
